@import 'src/styles/constants';
.title {
  font-size: 1.5em !important;
  color: white !important;
}
.subTitle {
  color: #949ccf;
  font-size: 1.2em;
  font-weight: normal;
  text-align: center;
  margin: auto;
  margin-top: 30px;
  line-height: 30px;
}
.container {
  // opacity: 0.8;
  background: linear-gradient(143.53deg, #498d7d 21.25%, #5f8d74 78.75%),
    #0b1030;
  position: relative;
  border-radius: 50px;
  width: 100% !important;
  // height: 670px !important;
  max-width: 600px !important;
  // max-height: 700px !important;
  padding: 0 50px 30px !important;
}
.header {
  display: flex !important;
  padding-top: 30px !important;
  margin-bottom: 60px;
}
.formContainer {
  margin: 0 auto !important;
}
.form {
  margin: 0 auto !important;
  align-items: center !important;
  min-height: 100px;
  padding-top: 30px !important;
  margin-bottom: 30px !important;
  color: white;
}
.backButton {
  // flex: 0.05;
  flex: 1;
  display: flex;
  align-items: center;
  // padding-top: 50px;
}
.backText {
  color: #fff;
  font-size: 0.9em;
  cursor: pointer;
  font-weight: 800;
}
.center {
  flex: 0.9;
}
.headerTextContainer {
  padding-top: 1.5em !important;
}
.title {
  // font-size: 2em !important;
  font-weight: 900 !important;
  color: #ffffff !important;
  text-shadow: 0px 0px 8px #f8cc46;
  font-size: 40px !important;
}
.subTitleSection {
  padding-top: 2em !important;
}
.subTitle {
  font-size: 1em !important;
  font-weight: 900 !important;
}
.subTitleShare {
  font-size: 1.5em !important;
  color: #f4bb41 !important;
  // background: -webkit-linear-gradient(
  //   97.19deg,
  //   #f7cb45 -63.37%,
  //   #f08b34 111.5%
  // );
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
}
.subTitleShareText {
  font-size: 0.7em !important;
  font-weight: 800;
  color: white;
}
.icon {
  // color: #abb3e8;
  // font-size: 1em !important;
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.logo {
  // margin-top: 20px;
  // margin-bottom: 10px;
}
.sendCopy {
  text-align: left;
  width: 100%;
  line-height: 20px !important;
  margin-bottom: 10px !important;
}
.sendCopyText {
  font-size: 0.8em;
  color: #f4bb41 !important;
  line-height: 30px !important;
  // background: -webkit-linear-gradient(
  //   97.19deg,
  //   #f7cb45 -63.37%,
  //   #f08b34 111.5%
  // );
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  font-weight: 900;
}
.reportText {
  color: white;
  line-height: 0px !important;
  font-size: 0.4em;
  font-weight: 900;
}
.content {
  height: 550px;
  min-height: 550px;
  max-height: 550px;
  margin: 0px !important;
  align-items: center !important;
}
.buttonSubmitReport {
  font-family: $font-family;
  font-weight: 900;
  font-size: 20px;
  color: white;
  height: 45px;
  width: 100%;
  border-radius: 15px;
  border: 0px;
  background: #f4bb41;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.buttonSubmit {
  position: absolute;
  bottom: -35px;
  left: 0px;
  right: 0px;
  margin: auto;
  font-family: $font-family;
  font-weight: 900;
  font-size: 20px;
  color: white;
  height: 60px;
  width: 220px;
  min-width: 220px;
  padding: 10px 30px;
  border-radius: 15px;
  border: 0px;
  background: #f4bb41;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.shareButton {
  margin-left: 8px;
  margin-right: 8px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;

  :global(.react-share__ShareButton) {
    display: flex;
    align-items: center;
  }
}
.emailButton {
  background-color: #dedede;
  height: 48px;
  width: 48px;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
}
.hyperlinkButton {
  background-color: #dedede;
  height: 48px;
  width: 48px;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
}
.linkContainerRectangle {
  max-width: 400px;
  height: 48px;
  background: #fff;
  border-radius: 40px;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  margin-top: 51px;
  text-align: left !important;
  justify-content: left !important;
  padding-top: 0px !important;
  flex-wrap: nowrap;
  font-size: 15px;
  padding-left: 5px !important;
  padding-right: 10px !important;
}
.copySvgIconButton {
  border: none;
  outline: none;
  // padding-left: 15px;
  // padding-top: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  margin-right: 6px;
}
:global(#linkCopyInput),
:global(#linkCopyShare) {
  :global(span) {
    background: -webkit-linear-gradient(62.14deg, #503caf 0%, #4ba3e7 105.63%);
    color: #503caf !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    align-items: center;
    justify-content: center;
    display: flex;
    line-height: 13px;
    font-family: $font-family !important;
    font-size: 14px;
    font-weight: 500;
  }
}
:global(#linkCopyShare) {
  :global(span) {
    color: #f7cb45 !important;
    background: -webkit-linear-gradient(
      97.19deg,
      #f7cb45 -63.37%,
      #f08b34 111.5%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.shareUrlText {
  // position: absolute;
  // top: 14px;
}
.copyTextLink {
  text-align: left;
  margin-left: 20px;
  font-size: 14px;
}
.copySvgIcon {
  width: 15px;
}
.teamButton {
  margin: 0px !important;
  padding: 0px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.footer {
  width: 100%;
  margin-top: 40px;
  font-size: 12px;
  line-height: 20px;
  height: 34px;
  color: #fff !important;
}
.noPadding {
  margin: 0px !important;
  padding: 0px !important;
}
@media only screen and (max-width: 767px) {
  .container {
    padding: 0 20px 60px !important;
  }

  .header {
    margin-bottom: 10px;
  }

  .backText {
    display: none;
  }
  .rangeStartMinus,
  .rangeEndMinus {
    color: #ffffff;
    text-shadow: 0px 0px 6px #f8cc46;
    font-size: 2em;
    font-weight: 900;
  }
  .listBenefit {
    > li {
      font-size: 0.8em !important;
    }
  }
  .buttonSubmitReport {
    // font-size: 0.8em;
    text-shadow: unset;
  }
  .buttonSubmit {
    font-size: 0.8em;
  }
  .reportText {
    // display: none;
    line-height: unset !important;
  }
  .sendCopy {
    padding-bottom: 20px !important;
  }
  .footer {
    margin-top: 0px;
    font-size: 0.7em;
    color: #8086aa;
  }
}

@media only screen and (max-width: 400px) {
  .title {
    font-size: 30px !important;
  }

  .subTitleSection {
    padding-top: 1em !important;
  }

  .subTitleShare {
    font-size: 20px !important;
  }

  .sendCopyText {
    font-size: 20px;
    padding-bottom: 10px !important;
    display: inline-block;

    br {
      display: none;
    }
  }

  .linkContainerRectangle {
    margin-top: 30px !important;
    span {
      font-size: 13px;
    }
  }
}
