@import './constants';

body,
h1,
h2,
h3,
h4,
h5 {
  font-family: $font-family;
}

##{$namespace} {
  font-family: $font-family !important;
  background: transparent;
  width: 100%;
  height: 100%;
  font-size: 18px !important;
  max-width: 600px;
  max-height: 750px;

  * {
    font-family: $font-family !important;
  }
}

##{$namespace} .grid {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

##{$namespace} .app-container {
  width: 100%;
  max-width: 700px;
  height: 750px;
  overflow: visible;
  // max-height: 750px;
  // overflow-x: hidden;
  // overflow-y: hidden;
}

@media only screen and (max-width: 767px) {
  ##{$namespace} .app-container {
    height: 900px;
  }
}

##{$namespace} input::placeholder {
  font-weight: 300;
  // color: #777777;
}
##{$namespace} .inputError::placeholder {
  font-weight: normal;
  color: white;
}
@media only screen and (min-width: 1170px) {
  ##{$namespace} .vertical-timeline-element {
    margin-top: 0;
    margin-bottom: 35px;
  }
}
@media only screen and (max-width: 767px) {
  ##{$namespace} .ui.container {
    margin: 0 !important;
  }
}
##{$namespace} {
  input:focus,
  button:focus,
  div:focus {
    // border: none !important;
    outline: none !important;
    // border: 1px solid #CCC !important;
  }

  .vertical-timeline-element-icon {
    top: 5px !important;
    left: 15px !important;
  }

  .vertical-timeline::before {
    left: 24px !important;
    //  background: #F08B34;
    background: #f08b34;
    height: 305px !important;
    top: 5px !important;
    width: 2px !important;
  }

  .vertical-timeline {
    padding: 0em 0 !important;
  }
  .ui.grid > .row {
    padding-bottom: 0px !important;
  }
  .ui.header {
    margin: 0px !important;
  }
  .ui.checkbox label:hover::before,
  .ui.checkbox input:focus ~ label:before {
    background-color: transparent;
    border: 1px solid #ffffff;
  }
  .ui.checkbox label {
    font-size: 18px;
    font-weight: 400;
    color: white !important;
  }
  .ui.checkbox.checked label {
    font-size: 18px;
    font-weight: 900;
  }
  .ui.checkbox label:before {
    top: -2px;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: transparent;
    border: 1px solid #ffffff;
  }
  .ui.checkbox input:checked ~ .box:after,
  .ui.checkbox input:checked ~ label:after {
    opacity: 1;
    color: rgba(0, 0, 0, 0.95);
    content: '';
    top: -2px;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    // background-color: #4BA3E7;
    background: #f7cb45;
  }
}
