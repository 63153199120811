$font-family: Avenir, sans-serif;
$font-size: 1em;
$namespace: "strike-tax-tool";

##{$namespace} {
  font-size: 0.8em !important;
}

##{$namespace} .vertical-timeline-element-content .vertical-timeline-element-date {
  opacity: 1 !important;
  padding: 1em 0.2em;
  font-size: 1em !important;
  color: white !important;
}
##{$namespace} .vertical-timeline-element-content{
  margin-left: 50px !important;
}
@media only screen and (max-width: 600px) {
  ##{$namespace} {
    font-size: 0.8em !important;
  }
  ##{$namespace} .vertical-timeline-element-content .vertical-timeline-element-date {
    opacity: 1 !important;
    padding: 1.2em 0.5em;
  }
  ##{$namespace} .vertical-timeline-element-content{
    margin-left: 50px !important;
  }
}
